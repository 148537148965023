import { template as template_e4b7873bc2bd4d04b71325bbbc69eaf7 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import AboutPageUser from "discourse/components/about-page-user";
export default class LegacyAboutPageUsers extends Component {
    get users() {
        return this.args.users || [];
    }
    static{
        template_e4b7873bc2bd4d04b71325bbbc69eaf7(`
    {{#each this.users as |user|}}
      <AboutPageUser @user={{user}} />
    {{/each}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
