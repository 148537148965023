import { template as template_972ff117ef38495596ea7a41d064bb37 } from "@ember/template-compiler";
const FKText = template_972ff117ef38495596ea7a41d064bb37(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
