import { template as template_c27f4eecd0c64f6fa09bce22420b74a3 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c27f4eecd0c64f6fa09bce22420b74a3(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
