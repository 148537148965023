import { template as template_1d007f2f29d54f43ace314a233f247cf } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_1d007f2f29d54f43ace314a233f247cf(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
