import { template as template_c9d65d917c834c89b361fa412c1c9493 } from "@ember/template-compiler";
const FKLabel = template_c9d65d917c834c89b361fa412c1c9493(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
