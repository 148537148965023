import { template as template_f493abea0b4b48afb393edd66b2a0563 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_f493abea0b4b48afb393edd66b2a0563(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
